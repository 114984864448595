<template>
  <component :is="tag" class="facility-map parent-bg">
    <div class="section-inner">
      <div class="col-map">
        <GlobalMap :blok="mergedData" />
      </div>
      <div class="col-infos">
        <div class="wrapper">
          <h2 v-if="blok.title" class="size-m bold align-center">
            {{ blok.title }}
          </h2>
          <p v-if="mergedData.commercialName" class="size-xl bold align-center">
            {{ mergedData.commercialName }}
          </p>
          <p v-if="useFormattedAddress(mergedData)" class="size-s align-center">
            {{ useFormattedAddress(mergedData) }}
          </p>
          <ul
            v-if="mergedData.informations.length > 0"
            class="infos-list margin-s-top"
          >
            <li
              v-for="item in mergedData.informations"
              :key="item._uid"
              class="infos-item size-s"
            >
              <SvgIcon :name="item.information_icon" small />
              {{ item.information_text }}
            </li>
          </ul>
          <SimpleButton
            :href="`https://www.google.${config.public['country']}/maps/dir/?api=1&destination=${mergedData.address} + ${mergedData.postalCode} + ${mergedData.city}`"
            class="margin-s-top"
            :xl="true"
            @click="
              trackingStore.sendTrackingData({
                event: 'click.action',
                click: useSnakeCaseFilter(
                  useMicroCopy('facility map.cta.label')
                ),
                click_chapter1: 'maps',
                click_chapter2: 'CTA',
              })
            "
          >
            {{ useMicroCopy("facility map.cta.label") }}
          </SimpleButton>
        </div>
      </div>
    </div>
  </component>
</template>

<script setup>
const trackingStore = useTrackingStore();
const config = useRuntimeConfig();
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
  context: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  tag: {
    type: String,
    required: false,
    default: "div",
  },
});

const { blok, context } = toRefs(props); // eslint-disable-line
const mergedData = computed(() => ({ ...context.value, ...blok.value }));
</script>

<style lang="scss">
.background-facility-map {
  @include pair-7;

  .facility-map {
    @include pair-7;
  }

  &.alternate {
    @include pair-3;

      .facility-map {
        @include pair-3;

        .infos-item {
        @include pair-7;
      }
    } 
  }
}
</style>

<style lang="scss" scoped>
.facility-map {
  .section-inner {
    @include for-tablet-landscape-up {
      display: flex;
      align-items: center;
    }
  }

  .col-map {
    aspect-ratio: 1 / 1;
    border-radius: $radius-l;
    overflow: hidden;

    @include for-tablet-landscape-up {
      width: 50%;
    }
  }

  .col-infos {
    margin-top: 2.5rem;

    @include for-tablet-landscape-up {
      margin-top: 0;
      width: 50%;
      padding-left: 2.5rem;
    }

    @include for-desktop-up {
      padding-left: 3.75rem;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .infos-list {
    overflow: hidden;
    border-radius: $radius-m;
    width: 100%;
  }

  .infos-item {
    @include pair-5;
    padding: 1rem 0.75rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    @include for-tablet-landscape-up {
      gap: 0.75rem;
    }

    &:not(:first-child) {
      margin-top: 0.125rem;
    }

    .icon {
      flex-shrink: 0;
    }
  }
}
</style>
